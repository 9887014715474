<template>
  <div id="contestTips">
    <img class="group" src="@/assets/img/tips/group-detail.png" alt="">
    <div class="contest">
      <img src="@/assets/img/tips/contest-detail.png" alt="">
      <a href="https://benqgroup.oss-cn-hangzhou.aliyuncs.com/design-competition-test/logo/%E5%A4%A7%E8%B5%9B%E8%B5%84%E6%96%99%E5%8C%85.zip"
        class="download-logo"></a>
    </div>
    <img class="copyright" src="@/assets/img/tips/copyright-detail.png" alt="">
    <img class="standard" src="@/assets/img/tips/standard.png" alt="">
    <img class="about" @click="goLink('https://3.cn/-109USWmJ')" src="@/assets/img/tips/about.png" alt="">
  </div>
</template>
<script>
export default {
  name: "contestInformation",
  setup() {
    function goLink(item) {
      window.location.href = item;
    }
    return {
      goLink
    }
  }
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: "FZLTCHJW";
  src: url("../assets/fonts/FZLTCHJW.TTF") format("truetype");
}

@font-face {
  font-family: "FZLTHJW";
  src: url("../assets/fonts/FZLTHJW.TTF") format("truetype");
}

#contestTips {
  margin: 0 auto;
  height: 4957px;
  padding-top: 149px;
  box-sizing: border-box;
  background-image: url("../assets/img/tips/bg.png");
  background-size: auto 100%;
  background-position: center;

  &>div {
    margin: 0 auto;
  }

  &>img {
    display: block;
    margin: 0 auto;
  }

  .group {
    width: 956px;
    margin-bottom: 80px;
  }

  .contest {
    position: relative;
    width: 956px;
    margin-bottom: 100px;

    .download-logo {
      position: absolute;
      top: 269px;
      left: 580px;
      width: 100px;
      height: 30px;
      opacity: .1;
      cursor: pointer;
    }
  }

  .copyright {
    width: 956px;
    margin-bottom: 121px;
  }

  .standard {
    width: 1057px;
    margin-bottom: 205px;
  }

  .about {
    margin-top: 160px;
    width: 940px;
  }
}

#contestTips/deep/.el-pagination .btn-next,
#contestTips/deep/ .el-pagination .btn-prev {
  background-color: transparent;
}

#contestTips/deep/.el-pager li {
  background-color: transparent;
}

#contestTips/deep/.el-pager li.active {
  color: #4576d4;
}
</style>
